.App {
  transition: all 0.5s ease;
}
.library-active {
  margin-left: 30%;
}

@import "./song";
@import "./player";
@import "./library";
@import "./slider";

//   color: #222326;
