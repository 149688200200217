.player-container {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.audio-player {
  width: 60%;
  padding-bottom: 2vh;
}

.rhap_container {
  outline: none;
}

.rhap_progress-container {
  outline: none;
}

@media screen and (max-width: 768px) {
  .audio-player {
    width: 90%;
  }
}
