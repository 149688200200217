.img-container {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #222326;
}

.img-container:hover image {
  opacity: 0.3;
}

.img-container:hover .middle {
  opacity: 1;
}

.twitter-button {
  background-color: white;
  border: none;
  text-decoration: none;
  transition-duration: 0.4s;
}

.twitter-button:hover {
  background-color: lightgray;
  color: white;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}
